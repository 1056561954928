// src/components/Selection.js
import React from "react";

const Selection = () => {
  return (
    <div>
      <h1>延选</h1>
      <p>延选内容展示。</p>
    </div>
  );
};

export default Selection;
